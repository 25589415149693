import React from "react";
import styled from "styled-components";
import { Content } from "..";
import GatsbyImage from "gatsby-image";
import { mq, css } from "../../../styles";
import { graphql } from "gatsby";

const GrayContainer = styled.div`
  background: #f2f6f9;
  padding: 45px 0px;
`;
const CallOutText = styled.div`
  *:last-child {
    margin-bottom: 0;
  }
`;

const IconBox = styled.div`
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-self: auto;
  margin-top: auto;
`;
const GrayBlock = ({ icon, children, attribute, ...props }) => {
  return (
    // <Content>
    <GrayContainer {...props}>
      <CallOutText>{children}</CallOutText>
      {icon ? (
        <IconBox>
          <GatsbyImage fixed={icon.childImageSharp.fixed} />
        </IconBox>
      ) : (
        ""
      )}
    </GrayContainer>
    // </Content>
  );
};

export default GrayBlock;

export const query = graphql`
  fragment IconCalloutImage on File {
    childImageSharp {
      fixed(width: 150, height: 150, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { Link, withPrefix } from "gatsby";
import styled from "styled-components";
import { getWalletUrls } from "../../../utils";
import { brand, mq } from "../../../styles";
import english from "../../../../content/translations/en-CA/footermenu.json";
import french from "../../../../content/translations/fr-CA/footermenu.json";

const basePath = withPrefix("/social/");
const currentYear = new Date().getFullYear();

const FooterWrapper = styled.footer`
  background: #333333;
  font-size: 18px;
  position: relative;
  z-index: 300;
  color: white;
  padding: 20px 7% 15px 7%;
  @media screen and (max-width: 1017px) {
    font-size: 14px;
    padding: 20px 4% 15px 4%;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    &.products-footer-list {
      display: inline-grid;
      grid-template-columns: auto auto;
      @media screen and (max-width: 610px) {
        grid-gap: 0 50%;
      }
      @media screen and (max-width: 400px) {
        grid-gap: 0 30%;
      }
      @media screen and (max-width: 330px) {
        grid-gap: 0 0%;
      }
      li {
        margin-right: 50px;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &[]:after {
      display: none;
    }
  }

  .column {
    background-color: #252525;
    border-radius: 10px;
    float: left;
    width: 20%;
    height: 225px;
    max-width: 175px;
    padding: 15px 20px;
    min-height: 200px;
    @media screen and ${mq.maxMd} {
      max-width: 150px;
    }
    &.products-column {
      min-width: 290px;
      // width: 20%;
      margin: 0px 30px;
      @media screen and ${mq.maxMd} {
        margin: 0px 10px;
        min-width: 240px;
      }
      @media screen and (max-width: 610px) {
        margin: 0px;
        min-width: auto;
      }
    }
    &:last-child {
      width: 20%;
      background-color: transparent;
      float: right;
      padding-right: 0px;
      @media screen and (max-width: 761px) {
        display: flex;
        margin: 5px auto;
        float: none;
        align-items: center;
        justify-content: center;
        min-height: auto;
        padding-top: 20px;
        width: 100%;
      }
      @media screen and (max-width: 610px) {
        justify-content: flex-start;
      }
    }
    h6 {
      max-width: 100% !important;
      border-bottom: 1px solid #797979;
      @media screen and (max-width: 610px) {
      }
    }
    .points-footer-logo {
      .points-logo {
        height: 75px;
        float: right;
      }
    }
    h6 {
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      max-width: 80%;
      font-size: 18px;
      border-bottom: 1px solid rgba(#000, 0.3);
    }
    @media screen and ${mq.maxMd} {
      width: 24%;
    }
    @media screen and (max-width: 700px) {
      width: 50%;
    }
    @media screen and (max-width: 610px) {
      width: 100%;
      max-width: none;
      margin: 10px auto;
    }
  }

  .footer-legal {
    font-size: 12px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid rgba(#000, 0.2);
    margin-bottom: 0;
    // text-align: center;
    @media screen and (max-width: 761px) {
      padding-top: 0;
      padding-left: 1rem;
    }
    .copyrightNotice {
      margin-right: 55px;
      @media screen and (max-width: 610px) {
        margin-right: 15px;
      }
    }
    @media screen and (min-width: 700px) {
      text-align: left;
    }

    p {
      margin-bottom: 0;
    }

    ul,
    li {
      display: inline-block;
      margin-left: 5px;
      @media screen and (max-width: 610px) {
        margin-left: 0px;
      }
    }

    li a {
      margin-left: 0;
      margin-right: 10px;
      @media screen and (min-width: 700px) {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }

  ul.social-nav {
    text-align: center;
    float: none;
    display: block;
    padding-top: 1rem;

    @media screen and (min-width: 700px) {
      padding-top: 0;
      float: right;
      text-align: right;
    }
    @media screen and (max-width: 610px) {
      display: flex;
      justify-content: space-between;
    }
    li {
      &:not(:last-child) {
        margin-right: 1rem;
        @media screen and (min-width: 700px) {
          margin-right: 0;
        }
      }
    }
  }

  .footer-menu {
    // display:flex;
    // justify-content: space-around;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .footer-logos {
    margin: 2rem auto;
    text-align: center;

    img {
      height: 100px;
      opacity: 0.5;
    }
  }

  .social-nav {
    li {
      a {
        opacity: 0.8;
        display: inline-block;
        height: 17px;
        width: 17px;
      }

      &:hover a {
        opacity: 1;
      }
    }
  }

  .breadcrumbs {
    padding: 5px 0;
    color: rgba(#fff, 0.3);
    background: darken(#286ff7, 25%);

    a {
      color: white;
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

const Footer = () => {
  let lang = english;
  const [signInUrl, setSignInUrl] = useState("");

  useEffect(() => {
    setSignInUrl(getWalletUrls().signInUrl);
  }, []);

  return (
    <>
      <FooterWrapper>
        <div className="container footer-menu">
          <div className="column">
            <h6>{lang.footer.about.title}</h6>
            <ul className="footer-list about=footer-list">
              <li>
                <Link to={lang.footer.about.about1.link}>
                  {lang.footer.about.about1.title}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.about.about2.link}>
                  {lang.footer.about.about2.title}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.about.about3.link}>
                  {lang.footer.about.about3.title}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.about.about4.link}>
                  {lang.footer.about.about4.title}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.about.about5.link}>
                  {lang.footer.about.about5.title}
                </Link>
              </li>
              {/* <li><Link to="/why-points/">Why Points?</Link></li>
              <li><Link to={'/everyday-accrual/'}>Everyday Accrual</Link></li>
              <li><Link to={'/travel-rewards/'}>Travel Rewards</Link></li>
                <li><Link to={'/currency-utility/'}>Currency Utility</Link></li> */}
            </ul>
          </div>
          <div className="column products-column">
            <h6>{lang.footer.products.title}</h6>
            <ul className="footer-list products-footer-list">
              <li>
                <Link to={lang.footer.products.get_link}>
                  {lang.footer.products.buy}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.products.move_link}>
                  {lang.footer.products.gift}
                </Link>
              </li>

              <li>
                <Link to={lang.footer.products.get_link}>
                  {lang.footer.products.accelerate}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.products.move_link}>
                  {lang.footer.products.transfer}
                </Link>
              </li>

              <li>
                <Link to={lang.footer.products.get_link}>
                  {lang.footer.products.topup}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.products.move_link}>
                  {lang.footer.products.exchange}
                </Link>
              </li>
              {/* <li><Link to={'/currency-utility/'}>Earn Mail</Link></li> */}
              <li>
                <Link to={lang.footer.products.get_link}>
                  {lang.footer.products.elite}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.products.keep_link}>
                  {lang.footer.products.reinstate}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.products.keep_link}>
                  {lang.footer.products.extend}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.products.earn_link}>
                  {lang.footer.products.earn}
                </Link>
              </li>
              <li>
                <Link to={lang.footer.products.redeem_link}>
                  {lang.footer.products.redeem}
                </Link>
              </li>
            </ul>
            <br></br>
          </div>
          <div className="column">
            <div className="points-footer-logo">
              <img
                src="https://points-ebgt.s3.amazonaws.com/points/apex/resources/Points_PGC_LOGO_INV_EN.png"
                className="points-logo"
                alt="points"
              />
            </div>
          </div>
        </div>
        <div className="container footer-legal">
          <span className="copyrightNotice">
            &copy; {currentYear} {lang.footer.bottom.title}
          </span>
          <ul>
            <li>
              <Link to={lang.footer.bottom.terms_link}>
                {lang.footer.bottom.terms_copy}
              </Link>
            </li>
            <li>
              <Link to={lang.footer.bottom.privacy_link}>
                {lang.footer.bottom.privacy_copy}
              </Link>
            </li>
            <li>
              <Link to={lang.footer.bottom.access_link}>
                {lang.footer.bottom.access_copy}
              </Link>
            </li>
            <li>
              <Link to={lang.footer.bottom.gdpr_link}>
                {lang.footer.bottom.gdpr_copy}
              </Link>
            </li>
            <li>
              <Link to={lang.footer.bottom.vulnerability_disclosure_link}>
                {lang.footer.bottom.vulnerability_disclosure_copy}
              </Link>
            </li>
          </ul>
          <ul className="social-nav">
            <li>
              <a
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/points"
              >
                <img alt="linked in logo" src={`${basePath}linkedin.svg`} />
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                href="https://www.facebook.com/pointsfans/"
              >
                <img alt="facebook logo" src={`${basePath}facebook.svg`} />
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                href="https://twitter.com/PointsLoyalty"
              >
                <img
                  alt="twitter logo"
                  height={17}
                  width={17}
                  src={`${basePath}twitter.svg`}
                />
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                href="https://www.instagram.com/pointsloyalty/"
              >
                <img alt="instagram logo" src={`${basePath}instagram.svg`} />
              </a>
            </li>
          </ul>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;

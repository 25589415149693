module.exports = [
  {
    title: "FR About Us",
    url: "/about/",
  },
  {
    title: " FR Products",
    submenu: [
      {
        title: "FR Currency Retailing",
        url: "/products/currency-retailing/",
      },
      {
        title: "Everyday Earn & Burn",
        url: "/products/everyday-earn/",
      },
    ],
  },
  {
    title: "FR Partners",
    url: "/partners/",
  },
  {
    title: "Careers",
    submenu: [
      {
        title: "Careers",
        url: "/careers/",
      },
      {
        title: "Current Openings",
        url: "/careers/current-openings/",
      },
    ],
  },
  {
    title: "Contact",
    url: "/contact/",
  },
];

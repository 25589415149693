import React from "react";

import { Layout, SEO } from "../components/structure";
import { Button, Content } from "../components/blocks";
import styled from "styled-components";
import { brand } from "../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const FourOhFourContainer = styled.div`
  padding-top: 200px;
  text-align: center;
  margin: 0 auto 50px;
  max-width: 850px;
  color: #fff;
`;

const ActionContainer = styled.div`
  > span {
    margin-bottom: 15px;
  }

  > span:not(:last-child) {
    display: inline-block;
    margin-right: 15px;
  }
`;

const NotFoundPage = props => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bigfoot: file(base: { eq: "404.png" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Content style={{ background: brand.gradient, paddingBottom: "4rem" }}>
        <FourOhFourContainer>
          <h1 style={{ color: "#fff" }}>404</h1>
          <h2 style={{ color: "#fff" }}>
            Sorry, neither bigfoot nor this page exist (unless you can prove us
            wrong)
          </h2>
          <p>Try one of these instead:</p>
          <ActionContainer>
            {/* <Button type={'white'} destination={'/why-points/'} label={'Why Points?'} /> */}
            <Button type={"white"} destination={"/about/"} label={"Company"} />
            <Button
              type={"white"}
              destination={"/careers/"}
              label={"Careers"}
            />
          </ActionContainer>
          <GatsbyImage
            style={{ marginTop: 150 }}
            fluid={pageQuery["bigfoot"].childImageSharp.fluid}
          />
          <p
            style={{
              marginTop: -30,
              position: "relative",
              zIndex: "500",
              color: "#90533f",
            }}
          >
            Missing page: <code>{props.location.pathname}</code>
          </p>
        </FourOhFourContainer>
      </Content>
    </Layout>
  );
};

export default NotFoundPage;
